<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('web_setting.head_list11') }} </v-card-title>
        <v-card-text class="error--text"> {{ $t('web_setting.comment') }} </v-card-text>
        <v-divider class="mx-3" />
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="3" v-for="(i, n) in info" :key="n" class="d-flex flex-column py-0">
              <div>
                <label class="detail-color">{{ detailColors[n] }}</label>
                <color-picker-rgb-input class="color-picker-box" :index="n" :label="i.label" :colorRGBValue="i.color"
                  @setColor="i.color = $event" @setLabel="i.label = $event" />
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ColorPickerRgbInput from '@/components/input/ColorPickerRgbInput.vue'
export default {
  components: {
    ColorPickerRgbInput,
  },
  props: {
    info: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      themeColor: [],
      detailColors: [this.$t('web_setting.color_input1'), this.$t('web_setting.color_input2') + ' 1', this.$t('web_setting.color_input2') + ' 2', this.$t('web_setting.color_input2') + ' 3', this.$t('web_setting.color_input3'), this.$t('web_setting.color_input4'), this.$t('web_setting.color_input5'), this.$t('web_setting.color_input5') + ' 8', this.$t('web_setting.color_input5') + ' 9', this.$t('web_setting.color_input5') + ' 10', this.$t('web_setting.color_input5') + ' 11', this.$t('web_setting.color_input5') + ' 12',]
    }
  },
  methods: {
    colorFormat(colors) {
      return colors.map(color => {
        const data = {
          label: `rgb(${color.color[0]}, ${color.color[1]}, ${color.color[2]})`,
          color: {
            r: color.color[0],
            g: color.color[1],
            b: color.color[2],
            a: 1,
          },
          value: color
        }
        return data
      })
    }
  }
}
</script>
<style scoped>
.detail-color {
  font-size: 0.7rem;
  color: rgba(94, 86, 105, 0.68);
}

.color-picker-box {
  padding-top: 8px;
}
</style>
