<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-card :color="color" width="40px" height="40px" />
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="color"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="color" :label="label" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-color-picker v-model="color" dot-size="25" swatches-max-height="200" flat></v-color-picker>
        <v-card>
          <!-- <v-card-actions> -->
          <v-btn text color="black" @click="menu = false">cancel</v-btn>
          <v-btn text color="success" @click="$refs.menu.save(color)">ok</v-btn>
          <!-- </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    colorHexValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      menu: false,
      type: 'hex',
    }
  },
  computed: {
    color: {
      get() {
        return this.colorHexValue
      },
      set(value) {
        if (this.colorHexValue) {
          this.$emit('setColor', value)
        } else {
          this.$emit('setColor', value.hex)
        }
      },
    },
  },
}
</script>
