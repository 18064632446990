<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('web_setting.head') }}</h2>
    </v-col>
    <v-col class="d-flex flex-row px-6">
      <v-btn-toggle>
        <v-btn @click="changeTab(0)" :color="tab === 0 ? 'primary' : 'none'">{{ $t('web_setting.head') }}</v-btn>
        <v-btn @click="changeTab(1)" :color="tab === 1 ? 'primary' : 'none'">{{ $t('web_setting.head_game') }}</v-btn>
        <!-- <v-btn @click="changeTab(2)" :color="tab === 2 ? 'primary' : 'none'">{{ $t('web_setting.head_maintenance') }}</v-btn> -->
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" v-if="tab === 0">
      <v-col cols="12">
        <settings-general :info="dataInfo" :affiliateInfo="affiliateInfo" :cashbackInfo="cashbackInfo"
          @setValue="dataInfo = $event" @setAffiliate="affiliateInfo = $event" @setCashback="cashbackInfo = $event"
          @setImages="imagesInfo = $event" />
        <settings-wheels v-if="enabledWheel" :wheelBathCoin="dataInfo.wheel_bath_coin.toString()"
          :wheelCoinUse="dataInfo.wheel_coin_use.toString()" :info="wheelData"
          @setWheelBathCoin="dataInfo.wheel_bath_coin = parseInt($event)"
          @setWheelCoinUse="dataInfo.wheel_coin_use = parseInt($event)" @setValue="wheelData = $event" />
        <settings-theme-color :info="themeColor" @setValue="themeColor = $event" />
      </v-col>
      <v-divider />
      <v-col cols="12" class="d-flex align-end">
        <v-spacer />
        <span>
          <v-btn color="warning" class="mx-3"> {{ $t('back') }} </v-btn>
          <v-btn color="success" :loading="loading" @click="updateInfo"> {{ $t('edit') }} </v-btn>
        </span>
      </v-col>
    </v-col>
    <v-col cols="12" v-if="tab === 1">
      <v-col cols="12">
        <setting-games :gameInfo="gameInfo" @setGame="gameInfo = $event" @updateGame="updateGame"></setting-games>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import SettingGames from '@/components/settings/SettingGames.vue'
import SettingsGeneral from '@/components/settings/SettingsGeneral.vue'
import SettingsWheels from '@/components/settings/SettingsWheels.vue'
import SettingsThemeColor from '@/components/settings/SettingsThemeColor.vue'
import store from '@/store'
import { getCurrency, getCurrencyI18n } from '@/utils/currencyUtils'

export default {
  components: {
    SettingsGeneral,
    SettingsWheels,
    SettingsThemeColor,
    SettingGames,
  },

  data() {
    return {
      loading: false,
      tab: 0,
      dataInfo: {
        id: 0,
        name: '',
        line_id: '',
        line_url: '',
        logo: '',
        phone: '',
        title: '',
        alert_sound: '',
        description: '',
        keyword: '',
        clear_turn: 0,
        min_withdraw: 0,
        min_deposit: 0,
        min_deposit_auto: 0,
        line_notify_token: '',
        enable_line_notify: true,
        enable_line_notify_log: true,
        line_notify_token_log: '',
        register_member: '',
        min_deposit_register_member: 0,
        cashback_to_me: true,
        wheel_bath_coin: 0,
        wheel_coin_use: 0,
        banners: [],
        banner1: '',
        banner2: '',
        banner3: '',
        banner4: '',
        banner5: '',
        bgRegister1: '',
        bgRegister2: '',
        bgContact: '',
        bgMenuBar: ''
      },
      affiliateInfo: {
        1: { floor: 1, use: true, type: null, turn: 0, data: [] },
        2: { floor: 2, use: true, type: null, turn: 0, data: [] },
      },
      cashbackInfo: {
        id: 0,
        enable: 0,
        percent: 0,
        multiply_turnover: 0,
        max_credit: 0,
      },
      themeColor: [],
      wheelData: [],
      gameInfo: {},
      enabledWheel: false,
      bankSystem: ''
    }
  },
  async created() {
    this.addLogPage()
    this.loading = true
    try {
      await this.$store.dispatch('getSettingFeature').then(res => {
        const enabledWheel = res.filter(item => item.page === 'wheel')[0]?.value === 1 ? true : false
        this.enabledWheel = enabledWheel;
      })

      await this.$store.dispatch('getSettingWebInfo').then(res => {
        this.dataInfo = { ...res }

        if (this.dataInfo.banners) {
          let bannersConv = JSON.parse(this.dataInfo.banners);
          if (bannersConv.banner1 !== undefined) {
            this.dataInfo.banner1 = bannersConv.banner1
            this.dataInfo.banner2 = bannersConv.banner2
            this.dataInfo.banner3 = bannersConv.banner3
            this.dataInfo.banner4 = bannersConv.banner4
            this.dataInfo.banner5 = bannersConv.banner5
            this.dataInfo.bgRegister1 = bannersConv.bgRegister1
            this.dataInfo.bgRegister2 = bannersConv.bgRegister2
            this.dataInfo.bgContact = bannersConv.bgContact
            this.dataInfo.bgMenuBar = bannersConv.bgMenuBar
          } else {
            if (bannersConv[0]) {
              this.dataInfo.banner1 = bannersConv[0].banner1
              this.dataInfo.banner2 = bannersConv[0].banner2
              this.dataInfo.banner3 = bannersConv[0].banner3
              this.dataInfo.banner4 = bannersConv[0].banner4
              this.dataInfo.banner5 = bannersConv[0].banner5
              this.dataInfo.bgRegister1 = bannersConv[0].bgRegister1
              this.dataInfo.bgRegister2 = bannersConv[0].bgRegister2
              this.dataInfo.bgContact = bannersConv[0].bgContact
              this.dataInfo.bgMenuBar = bannersConv[0].bgMenuBar
            }
          }

        } else {
          this.dataInfo.banner1 = ''
          this.dataInfo.banner2 = ''
          this.dataInfo.banner3 = ''
          this.dataInfo.banner4 = ''
          this.dataInfo.banner5 = ''
          this.dataInfo.bgRegister1 = ''
          this.dataInfo.bgRegister2 = ''
          this.dataInfo.bgContact = ''
          this.dataInfo.bgMenuBar = ''
        }
      })

      await this.getColorWebSettings().then(result => {
        let filterColor = []
        result?.forEach((el, i) => {
          switch (i) {
            case 0:
              break;
            case 7:
              break;
            case 8:
              break;
            case 9:
              break;
            case 11:
              break;
            default:
              filterColor.push(el)
              break;
          }
        })
        this.themeColor = filterColor
      })

      await this.getAffiliateSettings().then(result => {
        this.affiliateInfo = result
      })

      await this.getCashbackSettings().then(result => {
        this.cashbackInfo = result
      })

      await this.getGamesSettings().then(result => {
        this.gameInfo = result.data
      })

      if (this.enabledWheel) {
        await this.getWheelWebSettings().then(result => {
          this.wheelData = result
        })
      }
    } catch (e) { }
    this.getWebSettingInfo()
    this.loading = false
  },
  methods: {
    async addLogPage() {
      try {
        let data
        let tab = this.tab
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'ตั้งค่าเว็บ',
            url: window.location.href,
            detail: tab === 0 ? 'ตั้งค่าระบบทั่วไป' : 'ตั้งค่าเกม',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async updateInfo() {
      this.loading = true
      try {
        let dataInfoTemp = { ...this.dataInfo }
        dataInfoTemp.clear_turn = parseFloat(this.dataInfo.clear_turn)
        dataInfoTemp.min_withdraw = parseFloat(this.dataInfo.min_withdraw)
        dataInfoTemp.min_deposit = parseFloat(this.dataInfo.min_deposit)
        dataInfoTemp.min_deposit_auto = parseFloat(this.dataInfo.min_deposit_auto)
        let cashbackInfoTemp = {
          id: this.cashbackInfo.id,
          enable: this.cashbackInfo.enable === 1 ? true : false,
          percent: parseFloat(this.cashbackInfo.percent),
          multiply_turnover: parseFloat(this.cashbackInfo.multiply_turnover),
          max_credit: parseFloat(this.cashbackInfo.max_credit),
        }
        dataInfoTemp.enable_line_notify = dataInfoTemp.enable_line_notify === 1 ? true : false
        dataInfoTemp.enable_line_notify_log = dataInfoTemp.enable_line_notify_log === 1 ? true : false
        dataInfoTemp.cashback_to_me = dataInfoTemp.cashback_to_me === 1 ? true : false
        dataInfoTemp.min_deposit_register_member = parseFloat(dataInfoTemp.min_deposit_register_member)
        dataInfoTemp.banners = `{ "banner1":"${dataInfoTemp.banner1}", "banner2":"${dataInfoTemp.banner2}", "banner3":"${dataInfoTemp.banner3}", "banner4":"${dataInfoTemp.banner4}", "banner5":"${dataInfoTemp.banner5}", "bgRegister1":"${dataInfoTemp.bgRegister1}", "bgRegister2":"${dataInfoTemp.bgRegister2}", "bgContact":"${dataInfoTemp.bgContact}", "bgMenuBar":"${dataInfoTemp.bgMenuBar}"}`
        await this.$store.dispatch('updateSettingWebInfo', dataInfoTemp)
        await this.$store.dispatch('updateSettingWebCashback', cashbackInfoTemp)
        const affiliateData = this.setUpdateAffiliateFormat(this.affiliateInfo)
        for (const no in affiliateData) {
          await this.$store.dispatch('updateSettingWebAffiliate', affiliateData[no])
        }
        for (const no in this.wheelData) {
          let wheelTemp = { ...this.wheelData[no] }
          wheelTemp.percent = parseInt(wheelTemp.percent)
          wheelTemp.credit = parseInt(wheelTemp.credit)
          await this.$store.dispatch('updateSettingWebWheel', wheelTemp)
        }
        const theme = this.setArrayColorFormat(this.themeColor)
        for (const no in theme) {
          await this.$store.dispatch('updateSettingWebColor', theme[no])
        }
        this.$swal.fire(this.$t('web_setting.alert_complete'), '', 'success')
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    async getColorWebSettings() {
      try {
        const res = await this.$store.dispatch('getSettingWebColor')
        return this.colorFormat(res)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async getWheelWebSettings() {
      try {
        const res = await this.$store.dispatch('getSettingWebWheel')
        return res
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async getAffiliateSettings() {
      try {
        let floor = {
          1: { floor: 1, use: true, type: null, turn: 0, data: [] },
          2: { floor: 2, use: true, type: null, turn: 0, data: [] },
        }
        const res = await this.$store.dispatch('getSettingWebAffiliate')
        res.forEach(element => {
          floor[element.floor].data.push(element)
          floor[element.floor].use = element.use ? true : false
          floor[element.floor].turn = element.multiply_turnover
          floor[element.floor].type = element.type_bonus
        })
        return floor
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async getCashbackSettings() {
      try {
        const res = await this.$store.dispatch('getSettingWebCashback')
        return res
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async getGamesSettings() {
      try {
        const res = await this.$store.dispatch('getSettingGames')
        return res
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async updateGame(data) {
      try {
        await this.$store.dispatch('updateSettingGames', data);
        const result = await this.getGamesSettings();
        this.gameInfo = result.data;

        this.$swal.fire(this.$t('bank.alert_complete'), '', 'success')
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    colorFormat(colors) {
      return colors.map(color => {
        const data = {
          label: `rgb(${color.color[0]}, ${color.color[1]}, ${color.color[2]})`,
          color: {
            r: color.color[0],
            g: color.color[1],
            b: color.color[2],
            a: 1,
          },
          id: color.id,
        }
        return data
      })
    },
    setArrayColorFormat(colors) {
      return colors.map(color => {
        return {
          id: color.id,
          color: [color.color.r, color.color.g, color.color.b],
        }
      })
    },
    setUpdateAffiliateFormat(value) {
      const floor1 = value['1'].data.map(item => {
        return {
          floor: 1,
          use: 1,
          percent: parseFloat(item.percent),
          type_game: item.type_game,
          // type_bonus: item.type_bonus ? item.type_bonus.toString() : null,
          type_bonus: value['1'].type,
          // max_bonus: value['1'].type,
          max_bonus: item.max_bonus.toString(),
          multiply_turnover: value['1'].turn.toString(),
        }
      })
      const floor2 = value['2'].data.map(item => {
        return {
          floor: 2,
          use: value['2'].use ? 1 : 0,
          percent: parseFloat(item.percent),
          type_game: item.type_game,
          type_bonus: value['2'].type,
          max_bonus: item.max_bonus.toString(),
          multiply_turnover: value['2'].turn.toString(),
        }
      })
      return [...floor1, ...floor2]
    },
    changeTab(tab) {
      this.tab = tab
      this.addLogPage()
    },
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    getCurrency() {
      return getCurrency(this.bankSystem);
    },
    getCurrencyI18n(message) {
      return getCurrencyI18n(this.bankSystem, message);
    },

  },
}
</script>
