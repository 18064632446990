<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-card :color="label" width="40px" height="40px" />
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="color"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="label" :label="$t('web_setting.color_input5')" outlined dense readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-color-picker v-model="color" dot-size="25" flat swatches-max-height="200">
        </v-color-picker>
        <v-card>
          <!-- <v-card-actions> -->
          <v-btn text color="black" @click="menu = false">cancel</v-btn>
          <v-btn text color="success" @click="$refs.menu.save(color)">ok</v-btn>
          <!-- </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    colorRGBValue: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    color: {
      get() {
        return this.colorRGBValue
      },
      set(value) {
        this.$emit('setColor', value)
        this.$emit('setLabel', this.setColorLabel(value))
      },
    },
  },
  methods: {
    setColorLabel(color) {
      return `rgb(${color.r}, ${color.g}, ${color.b})`
    },
  },
}
</script>
